import { environment } from '@environments/environment';
import { createApiConfiguration, FfNgxEndpointDefinition } from '@fagforbundet/ngx-components';
import { AgreementApiEndpoints } from '@config/apis/agreement/agreement-api-endpoints';

const agreementApiConfig = createApiConfiguration<typeof AgreementApiEndpoints>(
  {
    baseUrl: environment.apis.agreement.baseUrl,
    endpoints: AgreementApiEndpoints,
  },
);

export const agreementApiNoAuthEndpoints: FfNgxEndpointDefinition[] = [
  agreementApiConfig.endpoints.GET_AGREEMENTS,
  agreementApiConfig.endpoints.GET_AGREEMENT_BY_FANE2_ID,
];

export default agreementApiConfig;
