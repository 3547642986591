import { EnvironmentTargetInterface } from '@environments/assets/environment-target.interface';

export const agreementApiTargets: EnvironmentTargetInterface = {
  production: {
    baseUrl: 'https://apim-fagf-prod.azure-api.net/legacy',
  },
  staging: {
    baseUrl: 'https://apim-fagf-test.azure-api.net/legacy',
  },
};
