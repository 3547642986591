import { FfNgxEndpointRecords } from '@fagforbundet/ngx-components';

export const AgreementApiEndpoints = {
  GET_AGREEMENT_BY_FANE2_ID: {
    path: '/v1/Agreement/fane2id/:fane2id',
  },
  GET_AGREEMENTS: {
    path: '/v1/Agreement',
  },
} satisfies FfNgxEndpointRecords;
